.menu {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2rem;
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
}

.item {
    padding: 0.4rem 1rem;
    border-radius: 2rem;
    background-color: var(--color-2-contrast);
    transition: background-color 0.2s, color 0.2s;
    letter-spacing: 1px;
    white-space: nowrap;
    font-size: 1.4rem;
    font-weight: 600;
    color: var(--color-2-dark);
    cursor: pointer;

    &:not(:last-child) {
        margin-right: 2rem;
    }
}

.active {
    @extend .item;
    background-color: var(--color-1);
    color: var(--color-2);
}

.list {
    width: 100%;

    li:not(:last-child) {
        margin-bottom: 2rem;
    }
}

.organization {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    > div:first-child {
        flex: 1;
        height: 100%;
        padding-right: 1rem;
    }

    > div:last-child {
        width: 2rem;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
    }
}

.name {
    font-weight: 600;
}

.plan {
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--color-2-dark);
}
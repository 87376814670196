@import "stylesheets/animations";

.back {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 3rem;
    font-size: 2rem;

    span {
        display: block;
        margin-left: 0.5rem;
        font-size: 1.6rem;
        font-weight: 600;
    }
}

.plan {
    margin-top: 4rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 3rem;
    font-weight: 800;
    color: var(--color-3);
}

.caption {
    margin-bottom: 5rem;
    font-size: 1.4rem;
    font-weight: 500;
    color: var(--color-2-dark);
}

.wrapper > * {
    margin-top: 1rem;
}

.subtitle {
    margin: 4rem 0 2rem 0;
}

.button {
    width: 100%;
    height: 4.5rem;
    margin-top: 3rem;
    border-radius: var(--border-radius);
    background-color: var(--color-1);
    font-weight: 500;
    color: var(--color-2);
}

.list {
    width: 100%;
    margin-bottom: 5rem;
}

.invoice {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    animation: $fadeIn;

    &:not(:last-child) {
        margin-bottom: 4rem;
    }
}

.icon {
    width: 7rem;
    height: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2rem;
    border-radius: 50%;
    background-color: var(--color-2-dimmed);
    font-size: 4rem;
}

.invoiceNumber {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.8rem;
    font-weight: 600;

    span {
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 1.2rem;
        font-weight: 600;
        color: var(--color-2-dark);
    }
}

.circle {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0 0.5rem 0 1rem;
    border-radius: 50%;
}

.paid {
    @extend .circle;
    background-color: var(--color-4);

    + span {
        color: var(--color-4);
    }
}

.unpaid {
    @extend .circle;
    background-color: var(--color-5);

    + span {
        color: var(--color-5);
    }
}

.timestamp {
    margin-bottom: 0.7rem;
    font-size: 1.4rem;
    font-weight: 500;
    color: var(--color-2-dark);
}

.actions {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.action {
    padding: 0.4rem 1rem;
    border-radius: 2rem;
    background-color: var(--color-1);
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--color-2);

    &:first-child {
        margin-right: 1rem;
        background-color: var(--color-3);
    }
}

.invoiceSpinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
}
.container {
    width: 100%;
    height: 100%;
    min-height: 25rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.small {
    @extend .container;
    min-height: unset;
    transform: scale(0.8);
}

.tiny {
    @extend .container;
    width: 2rem;
    height: 2rem;
    min-height: unset;
    transform: scale(0.5);
}

.spinner {
    position: relative;
    width: 8rem;
    height: 8rem;
    display: inline-block;
    transform: scale(0.55);
    color: var(--color-1);

    div {
        transform-origin: 40px 40px;
        animation: spinner 1.2s linear infinite;

        &:nth-child(1) {
            transform: rotate(0);
            animation-delay: -1.1s;
        }

        &:nth-child(2) {
            transform: rotate(30deg);
            animation-delay: -1s;
        }

        &:nth-child(3) {
            transform: rotate(60deg);
            animation-delay: -0.9s;
        }

        &:nth-child(4) {
            transform: rotate(90deg);
            animation-delay: -0.8s;
        }

        &:nth-child(5) {
            transform: rotate(120deg);
            animation-delay: -0.7s;
        }

        &:nth-child(6) {
            transform: rotate(150deg);
            animation-delay: -0.6s;
        }

        &:nth-child(7) {
            transform: rotate(180deg);
            animation-delay: -0.5s;
        }

        &:nth-child(8) {
            transform: rotate(210deg);
            animation-delay: -0.4s;
        }

        &:nth-child(9) {
            transform: rotate(240deg);
            animation-delay: -0.3s;
        }

        &:nth-child(10) {
            transform: rotate(270deg);
            animation-delay: -0.2s;
        }

        &:nth-child(11) {
            transform: rotate(300deg);
            animation-delay: -0.1s;
        }

        &:nth-child(12) {
            transform: rotate(330deg);
            animation-delay: 0s;
        }

        &:after {
            content: "";
            position: absolute;
            top: 3px;
            left: 37px;
            width: 6px;
            height: 18px;
            display: block;
            border-radius: 20%;
            background-color: var(--color-1);
        }
    }
}

@keyframes spinner {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
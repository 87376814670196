.nav {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: var(--nav-height);
    border-bottom: 1px solid var(--color-1-lighter);
    background-color: var(--color-1);
    backdrop-filter: blur(15px) saturate(1.2);
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.05);
    z-index: 99;
}

.container {
    position: relative;
    width: 100%;
    max-width: var(--max-width-narrow);
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 0 2rem;
}

.logo {
    width: auto;
    height: 38%;
}

.signOut {
    width: 4rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    color: var(--color-2);
}

@media screen and (min-width: 992px) {
    .container {
        max-width: var(--max-width);
    }
}
@import "stylesheets/animations";

.placeholder {
    width: 100%;
    height: 40rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    border-radius: var(--border-radius-large);
    background-color: var(--color-2-dimmed);
    text-align: center;
    animation: $fadeIn;
    animation-delay: 0.2s;

    .title {
        margin-bottom: 1rem;
        font-size: 2.8rem;
        font-weight: 800;
    }

    .subtitle {
        width: 100%;
        max-width: 50rem;
        margin-bottom: 2rem;
        font-size: 1.8rem;
        font-weight: 500;
        color: var(--color-2-dark);
    }
}

.regular {
    @extend .placeholder;
}

.smaller {
    @extend .placeholder;

    .title {
        font-size: 2.4rem;
    }

    .subtitle {
        font-size: 1.6rem;
    }
}
.input {
    margin-top: 2rem;
}

.controls {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 2rem;
}

.success {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    h1 {
        margin: 0 0 1rem 0;
    }

    a {
        width: 100%;
        height: 4.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 4rem;
        margin-bottom: 1rem;
        border-radius: var(--border-radius);
        background-color: var(--color-1);
        font-weight: 600;
        color: var(--color-2);
    }

    p {
        font-weight: 500;
    }
}

.tick {
    width: 15rem;
    height: 15rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}

.close {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3.5rem;
    color: var(--color-2-dark);
}
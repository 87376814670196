@font-face {
    font-family: 'metropolis';
    src: url('assets/fonts/metropolis-regular-webfont.woff2') format('woff2'),
    url('assets/fonts/metropolis-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'metropolis';
    src: url('assets/fonts/metropolis-medium-webfont.woff2') format('woff2'),
    url('assets/fonts/metropolis-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'metropolis';
    src: url('assets/fonts/metropolis-semibold-webfont.woff2') format('woff2'),
    url('assets/fonts/metropolis-semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'metropolis';
    src: url('assets/fonts/metropolis-bold-webfont.woff2') format('woff2'),
    url('assets/fonts/metropolis-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'metropolis';
    src: url('assets/fonts/metropolis-extrabold-webfont.woff2') format('woff2'),
    url('assets/fonts/metropolis-extrabold-webfont.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'metropolis';
    src: url('assets/fonts/metropolis-black-webfont.woff2') format('woff2'),
    url('assets/fonts/metropolis-black-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}


:root {
    --color-1-bright: #41474E;
    --color-1-lighter: #212529;
    --color-1-transparent: rgba(0, 0, 0, 0.75);
    --color-1: black;

    --color-2: white;
    --color-2-dimmed: #f5f6f7;
    --color-2-contrast: #DBE2F0;
    --color-2-darker: #E5ECF9;
    --color-2-dark: #5B6F8C;

    --color-3-bright: #D1E3FA;
    --color-3-brightened: #0559C7;
    --color-3: #106ee8;
    --color-3-darker: #0559C7;

    --color-4-bright: #DEEDE4;
    --color-4-brighter: #3cdd7f;
    --color-4-brightened: #25d46e;
    --color-4: #32c870;

    --color-5-bright: #FBF4F4;
    --color-5: #EF3E69;

    --color-6-bright: #fffaed;
    --color-6: #FBD204;

    --max-width: 135rem;
    --max-width-narrow: 70rem;
    --nav-height: 7rem;
    --padding: 2rem;

    --border-radius: 4px;
    --border-radius-large: 1.2rem;

    --shadow: 0 11px 20px rgba(16, 56, 107, 0.1);
}

html {
    box-sizing: border-box;
    font-size: 50%;
}

body {
    position: relative;
    display: block;
    margin: 0;
    background-color: var(--color-2);
    transition: background-color 0.4s;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.6rem;
    color: var(--color-1);
}

*, *:before, *:after {
    box-sizing: inherit;
    font-family: 'metropolis', sans-serif;
}

[hidden] {
    display: none !important;
}

button,
input {
    padding: 0;
    border: none;
    background: none;
    font-size: unset;
}

button {
    transition: transform 0.2s, color 0.2s;
    cursor: pointer;
}

button:disabled {
    cursor: default;
}

button:not(:disabled):active {
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
}

code {
    font-family: monospace;
    font-weight: 600;
}

h1 {
    margin: 0 0 2rem 0;
    font-size: 3rem;
    font-weight: 800;
}

h2 {
    margin: 0;
    font-size: 2.2rem;
    font-weight: 600;
}

h6 {
    margin: 0 0 0.4rem 0;
    font-size: 1.6rem;
    font-weight: 500;
}

hr {
    margin: 2rem 0;
    border: none;
    border-top: 1px solid var(--color-2-darker);
}

a {
    background-color: transparent;
    transition: color 0.2s;
    text-decoration: none;
    font-weight: 500;
    color: var(--color-1);
    cursor: pointer;
}

a:active,
a:focus {
    color: var(--color-1);
}

b,
strong {
    font-weight: bolder;
}

img {
    border-style: none;
}

p {
    line-height: 1.4;
    color: var(--color-2-dark);
}

p,
ul {
    margin: 0;
    padding: 0;
}

pre {
    margin: 0;
}

li {
    list-style-type: none;
}

@media screen and (min-width: 350px) {
    html {
        font-size: 55%;
    }
}

@media screen and (min-width: 992px) {
    :root {
        --padding: 4rem;
    }

    html {
        font-size: 62.5%;
    }
}

.simplebar-scrollbar:before {
    background: var(--color-2-dark);
}
.regularLabel {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 1.6rem;
    font-weight: 500;
    color: var(--color-1);
}

.input {
    position: relative;
    width: 100%;
    height: 3.6rem;
    margin-top: 0.5rem;
    margin-bottom: 0.3rem;
    padding: 0 0.8rem;
    border-radius: var(--border-radius);
    border: none;
    background-color: var(--color-2);
    box-shadow: 0 0 0 1px var(--color-2-contrast);
    -webkit-appearance: none;
    transition: box-shadow 0.2s;
    font-size: 1.6rem;
    color: var(--color-1);
    outline: none;

    &::placeholder {
        font-size: 1.4rem;
        color: var(--color-2-dark);
    }

    &:focus {
        box-shadow: 0 0 0 2px var(--color-1);
    }

    &:disabled {
        background-color: var(--color-2-dimmed);
    }
}

.larger {
    @extend .input;
    height: 4rem;
}

.invalid {
    box-shadow: 0 0 0 2px var(--color-5);

    &:focus {
        box-shadow: 0 0 0 2px var(--color-5);
    }
}

.textArea {
    height: 100%;
    min-height: 7rem;
    padding: 1rem;
    resize: none;
}

.caption {
    display: block;
    margin-left: 0.2rem;
    font-size: 1.2rem;
    font-weight: 400;
    color: var(--color-2-dark);
}
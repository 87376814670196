@import "stylesheets/animations";

.container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: var(--color-2);
    animation: $fadeIn;
}

.box {
    width: 100%;
    max-width: 45rem;
    margin: 10vh auto;
    padding: 2rem;

    h1 {
        margin-bottom: 2rem;
        font-size: 3rem;
    }

    img {
        width: 15rem;
        height: auto;
        display: block;
        margin: 0 auto 7rem auto;
        filter: invert(1);
    }

    label:not(:last-of-type) {
        margin-bottom: 1.5rem;
    }

    label:last-of-type {
        margin-bottom: 0.5rem;
    }

    a {
        width: 100%;
        display: block;
        text-align: right;
        font-size: 1.4rem;
    }
}

.buttonWrapper {
    width: 100%;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

.button {
    width: 100%;
    height: 4.5rem;
    border-radius: var(--border-radius);
    background-color: var(--color-1);
    animation: $fadeIn;
    font-weight: 500;
    color: var(--color-2);
}
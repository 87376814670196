.button {
    min-width: 12rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1.5rem;
    border-radius: var(--border-radius);
    background-color: var(--color-1);
    transition: background-color 0.2s, border 0.2s, color 0.2s;
    font-size: 1.4rem;
    font-weight: 500;
    color: var(--color-2);

    &:nth-child(2) {
        margin-left: 1rem;
    }

    &:hover {
        background-color: var(--color-1-lighter);
    }

    &:disabled {
        background-color: var(--color-2-darker);
        color: var(--color-2-dark);
    }
}

.clear {
    @extend .button;
    border: 1px solid transparent;
    background-color: transparent;
    font-weight: 600;
    color: var(--color-1);

    &:hover {
        border: 1px solid var(--color-1);
        background-color: transparent;
    }
}

.revertedLight {
    @extend .button;
    border: 1px solid var(--color-2);
    background-color: transparent;
    font-weight: 600;
    color: var(--color-2);

    &:hover {
        border: 1px solid var(--color-2);
        background-color: var(--color-2);
        color: var(--color-1);
    }
}

.danger {
    @extend .button;
    border: 1px solid transparent;
    background-color: transparent;
    font-weight: 600;
    color: var(--color-5);

    &:hover {
        border: 1px solid var(--color-5);
        background-color: var(--color-5);
        color: var(--color-2);
    }
}